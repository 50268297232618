import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import Divider from '../components/Divider';
import ModularBlocks from '../components/ModularBlocks';

const NewsInsightPageTemplate = ({
  data: {
    datoCmsNewsInsight: { seoMetaTags, title, featuredImage, modularBlocks },
  },
}) => {
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner heading={title} image={featuredImage} />
        <Divider />
        <ModularBlocks items={modularBlocks} />
        <Divider alt="true" />
      </main>
    </Layout>
  );
};

export const NewsInsightPageTemplateQuery = graphql`
  query NewsInsightPageTemplateQuery($id: String!) {
    datoCmsNewsInsight(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      featuredImage {
        ...BannerImageFragment
      }
      modularBlocks {
        ...ContactCallToActionModularBlockFragment
        ...ContentModularBlockFragment
        ...DownloadFormModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...IconCallToActionsModularBlockFragment
        ...ImageModularBlockFragment
        ...VideoModularBlockFragment
      }
    }
  }
`;

export default NewsInsightPageTemplate;
